<template>
  <v-main>

    <v-container
        id="review-payments-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-account-multiple-outline"
          class="px-5 py-5"
      >
        <v-card-text v-if="errors.length">
          <v-alert color="error"
                   dark
                   outlined
                   :key="i"
                   show variant="danger"
                   v-for="(error, i) in errors">
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-card-text>

          <v-simple-table v-if="paymentsList">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-subtitle-2 payment-date">Date</th>
                <th class="text-subtitle-2 payment-from">From</th>
                <th class="text-subtitle-2 payment-claim">Claim</th>
                <th class="text-subtitle-2 payment-amount text-right">Amount</th>
                <th class="text-subtitle-2 payment-method">Method</th>
                <th class="text-subtitle-2 payment-reference">Ref. #</th>
              </tr>
              </thead>

              <tbody>
              <tr
                  v-for="(payment, i) in paymentsList"
                  :key="i"
              >
                <td> {{ payment.date | formatDate }} </td>
                <td>

                  <router-link :to="{ name: 'view_invoice', params: {invoiceId: payment.invoiceNum} }">
                    {{ payment.from }}
                  </router-link>

                </td>
                <td> <span v-if="payment.claimUser"> {{ payment.claimUser }} </span> </td>
                <td class="text-right"> {{ payment.amount | formatCurrency }} </td>
                <td> {{ payment.method }} </td>
                <td> {{ payment.reference }} </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import constants from '@/utils/constants'

export default {
  name: 'DashboardReviewPaymentsView',

  created() {
    this.fetchReviewPayments()
  },

  computed: {
    paymentsList: {
      get() {
        const result = []
        this.invoices.forEach(inv => {
          inv.payments.forEach(pmt => {
            if (pmt.status !== constants.PAYMENT_STATUS_ADMIN_REVIEW) {
              return
            }
            const row = {
              invoiceNum: inv.id,
              from: inv.user.names,
              amount: pmt.amount,
              date: pmt.created,
              method: pmt.method,
              reference: pmt.reference
            }

            if (inv.claim !== undefined) {
              row.claimUser = inv.claim.user.names
            }

            result.push(row)
          })
        })

        return result
      }
    }
  },

  methods: {
    fetchReviewPayments() {
      api.fetchReviewPayments()
          .then(response => {
            this.invoices = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err, true)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      invoices: [],
      errors: [],
      paymentStatusMap: constants.getPaymentStatusMap()
    }
  }
}
</script>


<style lang="sass">
th.incident-date
  width: 10%

th.relationship-type, th.payment-action
  width: 5%

tr.highlight-new-payment
  background-color: #F1F8E9

th.payment-date, th.payment-method, th.payment-reference, th.payment-amount
  width: 10%

</style>